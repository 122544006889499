



























import { useTruckingSettlement } from "@/hooks/useTruckingSettlement";
import MNotification from "@/mixins/MNotification.vue";
import { FormModel } from "ant-design-vue";
import { Component, Prop, Ref } from "vue-property-decorator";

@Component({})
export default class RejectModal extends MNotification {
  @Prop({ type: Boolean, required: true, default: false })
  visible!: boolean;

  @Prop({ type: String, required: true })
  settlementId!: string;

  @Prop({ type: String, required: true })
  ujoNumber!: string;

  @Ref("formModel")
  formModel!: FormModel;

  formData = {
    reason: "",
  };

  formRules = {
    reason: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
  };

  loading = false;

  handleCancel(): void {
    this.$emit("close");
  }

  rejecSettlement(): void {
    const { rejectSettlement } = useTruckingSettlement();

    this.loading = true;
    rejectSettlement(this.settlementId, { rejectReason: this.formData.reason })
      .then(() => {
        this.showNotifSuccess("notif_reject_success", {
          documentNumber: this.ujoNumber,
        });
        this.$emit("onReject");
        this.$emit("close");
      })
      .finally(() => {
        this.loading = false;
      });
  }

  handleSubmit(): void {
    this.formModel.validate((valid: boolean) => {
      if (!valid) return;

      this.rejecSettlement();
    });
  }
}
