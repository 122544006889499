import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  AssetMutationListDetailDto,
  AssetMutationListDetailParams,
  AssetMutationListDownloadParams,
  AssetMutationListDto,
  AssetMutationListParams,
} from "@/models/interface/asset-mutation";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class AssetMutationService extends HttpClient {
  getList(
    params?: AssetMutationListParams
  ): Promise<Pagination<AssetMutationListDto>> {
    return this.get<Pagination<AssetMutationListDto>>(Api.AssetMutationList, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListDetail(
    params?: AssetMutationListDetailParams
  ): Promise<AssetMutationListDetailDto[]> {
    return this.get<AssetMutationListDetailDto[]>(Api.AssetMutationListDetail, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  download(params?: AssetMutationListDownloadParams): Promise<Blob> {
    return this.get<Blob>(Api.AssetMutationListDownload, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const assetMutationService = new AssetMutationService();
