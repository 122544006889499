

















































































































































































































































import { buildTruckingSettlementDetail } from "@/builder/trucking-settlement/TruckingSettlementBuilder";
import RejectModal from "@/components/TruckingSettlement/RejectModal.vue";
import SettingMasterRateModal from "@/components/TruckingSettlement/SettingMasterRateModal.vue";
import currencyFilter from "@/filters/currency.filter";
import ppnFilter from "@/filters/ppn.filter";
import useSort from "@/hooks/useSort";
import { useTruckingSettlement } from "@/hooks/useTruckingSettlement";
import MNotification from "@/mixins/MNotification.vue";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import {
  TruckingSettlementApproveRequestDto,
  TruckingSettlementDetailResponseDto,
  TruckingSettlementRateTableColumnDto,
} from "@/models/interface/trucking-settlement";
import { ColumnDef } from "@/types";
import { FormModel } from "ant-design-vue";
import moment from "moment";
import { Component, Ref } from "vue-property-decorator";

@Component({
  components: {
    RejectModal,
    SettingMasterRateModal,
  },
})
export default class TruckingSettlementDetail extends MNotification {
  get isApproved(): boolean {
    return this.detail.status === "Approved";
  }

  get isRejected(): boolean {
    return this.detail.status === "Rejected";
  }

  get isNeedApproval(): boolean {
    return this.detail.status === "Need Approval";
  }

  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  detail: TruckingSettlementDetailResponseDto = buildTruckingSettlementDetail();

  total = {
    totalNominalUjo: "",
    totalActualUjo: "",
    totalDitagihkan: "",
  };

  @Ref("formModel")
  formModel!: FormModel;

  formData: TruckingSettlementApproveRequestDto = {
    description: "",
    settlementDate: null,
  };

  formRules = {
    description: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    settlementDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  loading = {
    find: false,
    approve: false,
  };

  columns = [
    {
      title: this.$t("lbl_rate_id"),
      dataIndex: "rateId",
      key: "rateId",
      customRender: text => text || "-",
      sorter: (a, b) => {
        return useSort().sortStr(a.rateId, b.rateId);
      },
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
    },
    {
      title: this.$t("lbl_rate_name"),
      dataIndex: "rateName",
      key: "rateName",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_nominal_ujo"),
      dataIndex: "nominalUjo",
      key: "nominalUjo",
      customRender: text => currencyFilter(text),
      align: "right",
    },
    {
      title: this.$t("lbl_actual_ujo"),
      dataIndex: "actualUjo",
      key: "actualUjo",
      customRender: text => currencyFilter(text),
      align: "right",
    },
    {
      title: this.$t("lbl_billed"),
      dataIndex: "ditagihkan",
      key: "ditagihkan",
      customRender: text => currencyFilter(text),
      align: "right",
    },
    {
      title: this.$t("lbl_ppn_or_non_ppn"),
      dataIndex: "ppn",
      key: "ppn",
      customRender: ppn => ppnFilter(ppn),
      align: "right",
    },
  ] as ColumnDef[];

  rejectModal = {
    visible: false,
    reset(): void {
      this.visible = false;
    },
  };

  settingMasterRateModal = {
    visible: false,
    rateNames: [] as TruckingSettlementRateTableColumnDto[],
    reset(): void {
      this.visible = false;
      this.rateNames = [];
    },
  };

  findTruckingDetail(): void {
    const id = this.$route.params.id;

    const { findSettlementDetail } = useTruckingSettlement();
    this.loading.find = true;
    findSettlementDetail(id)
      .then(res => {
        this.detail = res;
        if (res.settlementDate) {
          this.formData.settlementDate = moment(res.settlementDate);
        }
        this.total.totalActualUjo = currencyFilter(res.totalActualUjo);
        this.total.totalNominalUjo = currencyFilter(res.totalNominalUjo);
        this.total.totalDitagihkan = currencyFilter(res.totalDitagihkan);
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  approveSettlement(): void {
    const { approveSettlement } = useTruckingSettlement();

    const id = this.$route.params.id;
    this.loading.approve = true;
    approveSettlement(id, this.formData)
      .then(res => {
        if (res.status) {
          this.showNotifSuccess("notif_approve_success", {
            documentNumber: this.detail.ujoNumber,
          });
          this.findTruckingDetail();
        } else {
          this.settingMasterRateModal.rateNames = res.rate.map(r => ({
            rate: r,
          }));
          this.settingMasterRateModal.visible = true;
        }
      })
      .finally(() => {
        this.loading.approve = false;
      });
  }

  handleApprove(): void {
    this.formModel.validate((valid: boolean) => {
      if (!valid) {
        return;
      }

      this.approveSettlement();
    });
  }

  handleOpenRejectModal(): void {
    this.rejectModal.visible = true;
  }

  handleCloseRejectModal(): void {
    this.rejectModal.reset();
  }

  handleCloseSettingMasterRateModal(): void {
    this.settingMasterRateModal.reset();
  }

  mounted(): void {
    this.findTruckingDetail();
  }
}
