



































































































































































import { SearchBuilder } from "@/builder";
import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectCustomer from "@/components/custom/select/SelectCustomer.vue";
import dateFormat from "@/filters/date.filter";
import { setNumbering } from "@/helpers/common";
import { debounce } from "@/helpers/debounce";
import { generateUUID } from "@/helpers/uuid";
import { APagination, useBlob, useDate } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  DATE_TIME_DEFAULT_FORMAT,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import {
  AssetMutationListDetailDto,
  AssetMutationListDetailParams,
  AssetMutationListDownloadParams,
  AssetMutationListDto,
  AssetMutationListParams,
} from "@/models/interface/asset-mutation";
import { assetMutationService } from "@/services/asset-mutation.service";
import { assetSegmentService } from "@/services/asset-segment.service";
import { assetsServices } from "@/services/assets.service";
import { LabelInValue } from "@/types";
import { FormModel } from "ant-design-vue";
import moment from "moment";
import { Component, Ref, Vue } from "vue-property-decorator";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

type FormState = {
  branch: LabelInValue | undefined;
  category: LabelInValue[] | undefined;
  asOfDate: string;
  unitCode: LabelInValue | undefined;
  customer: LabelInValue | undefined;
  yearPlacement: string | undefined;
};

type AssetMutationListDetailState = {
  no: number;
  key: string;
} & AssetMutationListDetailDto;
type AssetMutationListState = {
  loading: boolean;
  key: string;
  no: number;
  details: AssetMutationListDetailState[];
} & AssetMutationListDto;

@Component({
  components: {
    DatePicker,
    SelectBranch,
    SelectCustomer,
  },
})
export default class HistoryUnits extends Vue {
  @Ref("form") form!: FormModel;

  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  dtAssetMutation: Pagination<AssetMutationListState> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  pagination = {
    limit: DEFAULT_PAGE_SIZE,
    page: DEFAULT_PAGE,
  };

  filterForm: FormState = {
    branch: undefined,
    asOfDate: moment().format(DEFAULT_DATE_FORMAT),
    yearPlacement: undefined,
    category: undefined,
    unitCode: undefined,
    customer: undefined,
  };
  categoryOption = {
    data: [] as Option[],
    search: undefined as string | undefined,
    fetching: true,
  };

  unitCodeOption = {
    data: [] as Option[],
    search: undefined as string | undefined,
    fetching: true,
  };
  loading = {
    find: false as boolean,
    download: false,
  };

  columns = [
    {
      title: this.$t("lbl_number_short"),
      dataIndex: "no",
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      key: "unitCode",
    },
    {
      title: this.$t("lbl_equipment"),
      dataIndex: "equipment",
      key: "equipment",
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "merk",
      key: "merk",
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: this.$t("lbl_model"),
      dataIndex: "model",
      key: "model",
    },
    {
      title: this.$t("lbl_capacity"),
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNo",
      key: "serialNo",
    },
    {
      title: this.$t("lbl_specification"),
      dataIndex: "specification",
      key: "specification",
    },
  ];

  detailColumns = [
    {
      title: this.$t("lbl_number_short"),
      dataIndex: "no",
    },
    {
      title: this.$t("lbl_reference_no"),
      dataIndex: "referenceNo",
    },
    {
      title: this.$t("lbl_date"),
      dataIndex: "date",
      customRender: (text: string) => dateFormat(text),
    },
    {
      title: this.$t("lbl_do_number"),
      dataIndex: "doNumber",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_customer"),
      dataIndex: "customerName",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_location"),
      dataIndex: "location",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_notes"),
      dataIndex: "note",
      customRender: (text: string) => text || "-",
    },
  ];

  mounted(): void {
    this.getCategory();
    this.getUnitCode();
  }

  handleSubmit(): void {
    this.pagination.page = DEFAULT_PAGE;
    this.fetchList(this.buildParams(this.filterForm));
  }

  getUnitCode() {
    const params = new RequestQueryParams();
    if (this.unitCodeOption.search) {
      params.search = new SearchBuilder()
        .push(["unitCode", this.unitCodeOption.search], { like: "both" })
        .build();
    }

    this.unitCodeOption.fetching = true;
    assetsServices
      .getListMasterAsset(params)
      .then(response => {
        this.unitCodeOption.data = response.data.map(item => ({
          label: item.unitCode,
          key: item.id,
          value: item.id,
        }));
      })
      .finally(() => (this.unitCodeOption.fetching = false));
  }

  resetForm(): void {
    this.form.resetFields();
    this.filterForm.category = [];
  }

  fetchList(params?: AssetMutationListParams): void {
    this.loading.find = true;
    assetMutationService
      .getList(params)
      .then(response => {
        this.dtAssetMutation.currentPage = response.currentPage;
        this.dtAssetMutation.data = response.data.map<AssetMutationListState>(
          (item, index) => ({
            ...item,
            key: generateUUID(),
            no: setNumbering(params?.page, params?.limit, index),
            details: [],
            loading: false,
          })
        );
        this.dtAssetMutation.totalElements = response.totalElements;
        this.dtAssetMutation.totalPages = response.totalPages;
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  buildParams(state: FormState): AssetMutationListParams {
    const { toEndYear } = useDate();

    const params: AssetMutationListParams = {
      branchId: state.branch?.key,
      assetId: state.unitCode?.key,
      customerId: state.customer?.key,
    };

    if (state.category && state.category.length > 0) {
      params.categoryId = state.category
        .map(item => item.label.toUpperCase())
        .join(",");
    }

    if (state.yearPlacement) {
      const year: string = toEndYear(moment(state.yearPlacement)).format(
        DATE_TIME_DEFAULT_FORMAT
      );
      params.date = year;
    }

    return params;
  }

  searchDropdown(type: "category" | "unitCode", value?: string): void {
    switch (type) {
      case "category":
        this.categoryOption.search = value;
        debounce(() => {
          this.getCategory();
        }, 500);
        break;
      case "unitCode":
        this.unitCodeOption.search = value;
        debounce(() => {
          this.getUnitCode();
        }, 500);
        break;
      default:
        break;
    }
  }

  getCategory() {
    const params = new RequestQueryParams();
    if (this.categoryOption.search) {
      params.search = new SearchBuilder()
        .push(["first", this.categoryOption.search], { like: "both" })
        .build();
    }
    this.categoryOption.fetching = true;
    assetSegmentService
      .getSegmentParent(params)
      .then(response => {
        this.categoryOption.data = response.data.map(dataMap => {
          return {
            key: dataMap.id,
            label: dataMap.first,
            value: dataMap.id,
          };
        });
      })
      .finally(() => (this.categoryOption.fetching = false));
  }

  onChangeTable({ current, pageSize }: APagination): void {
    this.pagination.page =
      pageSize === this.pagination.limit ? current : DEFAULT_PAGE;
    this.pagination.limit = pageSize;

    const params: AssetMutationListParams = this.buildParams(this.filterForm);
    params.limit = this.pagination.limit;
    params.page = this.pagination.page - 1;
    this.fetchList(params);
  }

  onExpandRow(expanded: boolean, record: AssetMutationListState): void {
    if (!expanded) {
      record.details = [];
      return;
    }

    const params: AssetMutationListDetailParams = {
      ...this.buildParams(this.filterForm),
      assetId: record.assetId,
    };

    record.loading = true;
    assetMutationService
      .getListDetail(params)
      .then(response => {
        record.details = response.map((item, index) => ({
          key: generateUUID(),
          no: index + 1,
          ...item,
        }));
      })
      .finally(() => {
        record.loading = false;
      });
  }

  buildDownloadParams(state: FormState): AssetMutationListDownloadParams {
    const company =
      this.$store.state.authStore.authData.companyName ||
      "PT. SATRIA PIRANTI PERKASA";
    const branchName = state.branch?.label || "ALL";
    const unitCode = state.unitCode?.label || "ALL";
    const year = state.yearPlacement
      ? moment(state.yearPlacement).year()
      : "ALL";
    const customer = state.customer?.label || "ALL";

    let category = "ALL";
    if (state.category && state.category.length > 0) {
      category = state.category.map(item => item.label).join(";");
    }

    const params: AssetMutationListDownloadParams = {
      ...this.buildParams(state),
      params: `${company},${branchName},${category},${unitCode},${year},${customer}`,
    };

    return params;
  }

  handleDownload(): void {
    const { toDownload } = useBlob();
    const params: AssetMutationListDownloadParams = this.buildDownloadParams(
      this.filterForm
    );
    this.loading.download = true;
    assetMutationService
      .download(params)
      .then(response => {
        toDownload(response, "asset-mutation-report.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  onChangeCategory(e?: LabelInValue[]) {
    if (!e || e.length === 0) {
      this.categoryOption.search = undefined;
      this.getCategory();
      return;
    }
  }

  onChangeUnitCode(val?: LabelInValue): void {
    if (!val) {
      this.unitCodeOption.data = [];
      this.unitCodeOption.search = undefined;
      this.getUnitCode();
      return;
    }
  }
}
