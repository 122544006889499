var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_settlement_detail") } },
        [
          _vm.loading.find
            ? _c("a-skeleton", { attrs: { active: "" } })
            : [
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16] } },
                  [
                    _c(
                      "a-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "a-form-model",
                          {
                            ref: "formModel",
                            attrs: {
                              model: _vm.formData,
                              rules: _vm.formRules,
                              "label-align": "left",
                              layout: "vertical",
                              "label-col": { span: 8 },
                              "wrapper-col": { span: 14 }
                            }
                          },
                          [
                            _c(
                              "a-row",
                              [
                                _c(
                                  "a-col",
                                  { attrs: { lg: 12 } },
                                  [
                                    _c(
                                      "a-form-model-item",
                                      {
                                        attrs: {
                                          label: _vm.$t("lbl_ujo_number")
                                        }
                                      },
                                      [
                                        _c("a-input", {
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "lbl_ujo_number"
                                            ),
                                            value: _vm.detail.ujoNumber,
                                            disabled: true,
                                            "data-testid":
                                              "trucking-settlement-detail-ujo-number"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-form-model-item",
                                      {
                                        attrs: {
                                          label: _vm.$t("lbl_shipment_id")
                                        }
                                      },
                                      [
                                        _c("a-input", {
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "lbl_shipment_id"
                                            ),
                                            value: _vm.detail.shipmentId,
                                            disabled: true,
                                            "data-testid":
                                              "trucking-settlement-detail-shipment-id"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-form-model-item",
                                      {
                                        attrs: {
                                          label: _vm.$t("lbl_unit_code")
                                        }
                                      },
                                      [
                                        _c("a-input", {
                                          staticClass: "w-100",
                                          attrs: {
                                            value: _vm.detail.unitCode,
                                            placeholder: _vm.$t(
                                              "lbl_unit_code"
                                            ),
                                            disabled: true,
                                            "data-testid":
                                              "trucking-settlement-detail-unit-code"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    !_vm.isRejected
                                      ? _c(
                                          "a-form-model-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "lbl_settlement_date"
                                              ),
                                              prop: "settlementDate"
                                            }
                                          },
                                          [
                                            _c("a-date-picker", {
                                              staticClass: "w-100",
                                              attrs: {
                                                format: _vm.DEFAULT_DATE_FORMAT,
                                                placeholder: _vm.$t(
                                                  "lbl_settlement_date"
                                                ),
                                                disabled: !_vm.isNeedApproval,
                                                "data-testid":
                                                  "trucking-settlement-detail-settlement-date"
                                              },
                                              model: {
                                                value:
                                                  _vm.formData.settlementDate,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "settlementDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.settlementDate"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isNeedApproval
                                      ? _c(
                                          "a-form-model-item",
                                          {
                                            attrs: {
                                              label: _vm.$t("lbl_description"),
                                              prop: "description"
                                            }
                                          },
                                          [
                                            _c("a-textarea", {
                                              staticClass: "w-100",
                                              attrs: {
                                                placeholder: _vm.$t(
                                                  "lbl_description"
                                                ),
                                                "allow-clear": "",
                                                "data-testid":
                                                  "trucking-settlement-detail-description"
                                              },
                                              model: {
                                                value: _vm.formData.description,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "description",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.description"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "a-col",
                                  { attrs: { lg: 12 } },
                                  [
                                    _c(
                                      "a-form-model-item",
                                      {
                                        attrs: { label: _vm.$t("lbl_customer") }
                                      },
                                      [
                                        _c("a-input", {
                                          staticClass: "w-100",
                                          attrs: {
                                            value: _vm.detail.customer,
                                            placeholder: _vm.$t("lbl_customer"),
                                            disabled: true,
                                            "data-testid":
                                              "trucking-settlement-detail-customer"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-form-model-item",
                                      {
                                        attrs: {
                                          label: _vm.$t("lbl_sales_name")
                                        }
                                      },
                                      [
                                        _c("a-input", {
                                          staticClass: "w-100",
                                          attrs: {
                                            value: _vm.detail.sales,
                                            placeholder: _vm.$t(
                                              "lbl_sales_name"
                                            ),
                                            disabled: true,
                                            "data-testid":
                                              "trucking-settlement-detail-sales"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-form-model-item",
                                      {
                                        attrs: { label: _vm.$t("lbl_status") }
                                      },
                                      [
                                        _c("a-input", {
                                          attrs: {
                                            placeholder: _vm.$t("lbl_status"),
                                            value: _vm.detail.status,
                                            disabled: true,
                                            "data-testid":
                                              "trucking-settlement-detail-status"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm.isRejected
                                      ? _c(
                                          "a-form-model-item",
                                          {
                                            attrs: {
                                              label: _vm.$t("lbl_reason")
                                            }
                                          },
                                          [
                                            _c("a-textarea", {
                                              attrs: {
                                                placeholder: _vm.$t(
                                                  "lbl_reason"
                                                ),
                                                value: _vm.detail.rejectReason,
                                                disabled: true,
                                                "auto-size": true,
                                                "data-testid":
                                                  "trucking-settlement-detail-reason"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isApproved
                                      ? _c(
                                          "a-form-model-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "lbl_journal_number"
                                              )
                                            }
                                          },
                                          [
                                            _vm.detail.journalNumber &&
                                            _vm.detail.journalId
                                              ? _c(
                                                  "RouterLink",
                                                  {
                                                    attrs: {
                                                      to: {
                                                        name:
                                                          "generaljournal.detail",
                                                        params: {
                                                          id:
                                                            _vm.detail.journalId
                                                        }
                                                      },
                                                      target: "_blank",
                                                      "data-testid":
                                                        "trucking-settlement-detail-journal-number"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.detail
                                                            .journalNumber
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16] } },
                  [
                    _c(
                      "a-col",
                      { attrs: { span: 24 } },
                      [
                        _c("a-table", {
                          attrs: {
                            size: "small",
                            dataSource: _vm.detail.details,
                            columns: _vm.columns,
                            loading: _vm.loading.find,
                            scroll: { y: 600 },
                            rowKey: function(_record, index) {
                              return index
                            },
                            "data-testid": "settlement-detail-table",
                            "row-class-name": function(_record, index) {
                              return index % 2 ? "bg-white" : "bg-gray-light"
                            },
                            pagination: {
                              showTotal: function() {
                                return _vm.$t("lbl_total_items", {
                                  total: _vm.detail.details.length
                                })
                              },
                              total: _vm.detail.details.length
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16] } },
                  [
                    _c("a-col", { attrs: { span: 24 } }, [
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("common.total-text", {
                                text: _vm.$t("lbl_nominal_ujo"),
                                total: _vm.total.totalNominalUjo
                              })
                            ) +
                            " "
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("common.total-text", {
                                text: _vm.$t("lbl_actual_ujo"),
                                total: _vm.total.totalActualUjo
                              })
                            ) +
                            " "
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("common.total-text", {
                                text: _vm.$t("lbl_billed"),
                                total: _vm.total.totalDitagihkan
                              })
                            ) +
                            " "
                        )
                      ])
                    ])
                  ],
                  1
                ),
                _vm.isNeedApproval && _vm.$can("approve", "trucking-settlement")
                  ? _c(
                      "a-row",
                      { attrs: { gutter: [16, 16] } },
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 24, align: "end" } },
                          [
                            _c(
                              "a-space",
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      "data-testid":
                                        "settlement-detail-approve",
                                      loading: _vm.loading.approve
                                    },
                                    on: { click: _vm.handleApprove }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lbl_approve")) + " "
                                    )
                                  ]
                                ),
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      "data-testid": "settlement-detail-reject",
                                      loading: _vm.loading.approve
                                    },
                                    on: { click: _vm.handleOpenRejectModal }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lbl_reject")) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
        ],
        2
      ),
      _c("RejectModal", {
        attrs: {
          visible: _vm.rejectModal.visible,
          settlementId: _vm.detail.id,
          ujoNumber: _vm.detail.ujoNumber
        },
        on: {
          close: _vm.handleCloseRejectModal,
          onReject: _vm.findTruckingDetail
        }
      }),
      _c("SettingMasterRateModal", {
        attrs: {
          visible: _vm.settingMasterRateModal.visible,
          rateNames: _vm.settingMasterRateModal.rateNames
        },
        on: { close: _vm.handleCloseSettingMasterRateModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }