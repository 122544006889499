var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_report_of_history_units") } },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: {
            model: _vm.filterForm,
            "label-col": { span: 7 },
            "wrapper-col": { span: 12 },
            "label-align": "left",
            "data-testid": "history-unit-form"
          },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: 16 } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_customer"), prop: "customer" }
                    },
                    [
                      _c("SelectCustomer", {
                        attrs: {
                          "label-in-value": "",
                          "data-testid": "history-unit-customer"
                        },
                        model: {
                          value: _vm.filterForm.customer,
                          callback: function($$v) {
                            _vm.$set(_vm.filterForm, "customer", $$v)
                          },
                          expression: "filterForm.customer"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_branch"), prop: "branch" } },
                    [
                      _c("SelectBranch", {
                        attrs: {
                          "label-in-value": "",
                          "data-testid": "history-unit-branch"
                        },
                        model: {
                          value: _vm.filterForm.branch,
                          callback: function($$v) {
                            _vm.$set(_vm.filterForm, "branch", $$v)
                          },
                          expression: "filterForm.branch"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_category"), prop: "category" }
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          showSearch: true,
                          filterOption: false,
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_category")
                          }),
                          allowClear: true,
                          "label-in-value": "",
                          mode: "multiple",
                          options: _vm.categoryOption.data,
                          loading: _vm.categoryOption.fetching,
                          "data-testid": "history-unit-category"
                        },
                        on: {
                          search: function(value) {
                            return _vm.searchDropdown("category", value)
                          },
                          change: _vm.onChangeCategory
                        },
                        model: {
                          value: _vm.filterForm.category,
                          callback: function($$v) {
                            _vm.$set(_vm.filterForm, "category", $$v)
                          },
                          expression: "filterForm.category"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_unit_code"),
                        prop: "unitCode"
                      }
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          showSearch: true,
                          filterOption: false,
                          "data-testid": "history-unit-code",
                          "label-in-value": "",
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_unit_code")
                          }),
                          allowClear: true,
                          loading: _vm.unitCodeOption.fetching,
                          options: _vm.unitCodeOption.data
                        },
                        on: {
                          search: function(value) {
                            return _vm.searchDropdown("unitCode", value)
                          },
                          change: _vm.onChangeUnitCode
                        },
                        model: {
                          value: _vm.filterForm.unitCode,
                          callback: function($$v) {
                            _vm.$set(_vm.filterForm, "unitCode", $$v)
                          },
                          expression: "filterForm.unitCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_year_placement"),
                        prop: "yearPlacement"
                      }
                    },
                    [
                      _c("date-picker", {
                        attrs: {
                          type: "year",
                          "data-testid": "history-unit-year"
                        },
                        model: {
                          value: _vm.filterForm.yearPlacement,
                          callback: function($$v) {
                            _vm.$set(_vm.filterForm, "yearPlacement", $$v)
                          },
                          expression: "filterForm.yearPlacement"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_as_of_date"),
                        prop: "asOfDate"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          disabled: "",
                          "data-testid": "history-unit-as-of-date"
                        },
                        model: {
                          value: _vm.filterForm.asOfDate,
                          callback: function($$v) {
                            _vm.$set(_vm.filterForm, "asOfDate", $$v)
                          },
                          expression: "filterForm.asOfDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: 16, type: "flex" } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { "data-testid": "history-unit-reset" },
                          on: { click: _vm.resetForm }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.find,
                            "data-testid": "history-unit-find",
                            "html-type": "submit"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { staticClass: "mt-3", attrs: { type: "flex", gutter: 16 } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("a-table", {
                    attrs: {
                      "data-source": _vm.dtAssetMutation.data,
                      columns: _vm.columns,
                      size: "small",
                      "data-testid": "history-unit-table",
                      loading: _vm.loading.find,
                      pagination: {
                        showTotal: function() {
                          return _vm.$t("lbl_total_items", {
                            total: _vm.dtAssetMutation.totalElements
                          })
                        },
                        total: _vm.dtAssetMutation.totalElements,
                        showSizeChanger: true,
                        pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                        current: _vm.pagination.page,
                        defaultPageSize: _vm.pagination.limit
                      },
                      "row-class-name": function(_record, index) {
                        return index % 2 ? "bg-white" : "bg-gray-light"
                      }
                    },
                    on: { change: _vm.onChangeTable, expand: _vm.onExpandRow },
                    scopedSlots: _vm._u([
                      {
                        key: "expandedRowRender",
                        fn: function(record) {
                          return [
                            _c("a-table", {
                              attrs: {
                                "data-source": record.details,
                                columns: _vm.detailColumns,
                                size: "small",
                                loading: record.loading,
                                pagination: {
                                  showTotal: function() {
                                    return _vm.$t("lbl_total_items", {
                                      total: record.details.length
                                    })
                                  },
                                  showSizeChanger: true,
                                  pageSizeOptions: _vm.PAGE_SIZE_OPTIONS
                                },
                                "row-class-name": function(_, index) {
                                  return index % 2
                                    ? "bg-white"
                                    : "bg-gray-light"
                                }
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            {
              staticClass: "mt-3",
              attrs: { type: "flex", gutter: 16, justify: "end" }
            },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "html-type": "button",
                        loading: _vm.loading.download,
                        "data-testid": "history-unit-download"
                      },
                      on: { click: _vm.handleDownload }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }